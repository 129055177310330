import React, { Component } from 'react';
const $ = window.$;

class Entries extends Component {
  render() {
    let entries = this.props.entries.join('\n');
    let formFields = {};
    return (
      <div className="modal fade" id="entries" tabIndex="-1" role="dialog" data-backdrop="static" aria-labelledby="entriesModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-oswald text-uppercase" id="entriesModal">Raffle Entries</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onChange={
                (e) => {
                  e.preventDefault();
                  this.props.applyEntries(formFields.newEntries.value.split('\n'));
                }
              } >
                <div className="form-group">
                  <label htmlFor="entriesTextarea">Enter one raffle entry per line:</label>
                  <textarea
                    className="form-control"
                    id="entriesTextarea"
                    rows="8"
                    defaultValue={entries}
                    ref={input => formFields.newEntries = input}
                    aria-describedby="entriesNumber"
                  />
                  <small id="entriesNumber" className="form-text text-muted">
                    Number of entries: {this.props.entries.length}
                  </small>
                </div>
                <button
                  type="submit"
                  className="btn btn-success font-oswald text-uppercase"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.applyEntries(formFields.newEntries.value.split('\n'));
                      $('#entries').modal('hide');
                    }
                  } >
                  Apply Entries
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Entries;
