import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="modal fade" id="about" tabIndex="-1" role="dialog" aria-labelledby="aboutModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-oswald text-uppercase" id="aboutModal">About</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Visual Raffle is a free online and electronic raffle visualizer that makes your Facebook, Twitter, and Instagram giveaways more transparent and fun. See your raffle entries being shuffled real-time. Witness the countdown before a winner is chosen.
              </p>
              <p>
                Created by:
                <a href="https://nathanielcarolina.com/"
                  target="_blank"
                  rel="noreferrer noopener">
                  <i className="fas fa-laptop-code mx-1"></i>
                  Nathaniel Carolina
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
