import React, { Component } from 'react';

class Winner extends Component {
  render() {
    let winnerDisplay = this.props.shuffleOngoing === true ? "d-none" : "";
    return (
      <div id="winner" className={winnerDisplay}>
        <span className="badge badge-warning text-uppercase">Winner</span>
      </div>
    );
  }
}

export default Winner;
