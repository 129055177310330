import React, { Component } from 'react';
import NavbarBottom from './navbar-bottom.js';
import Entries from './entries.js';
import Navbar from './navbar.js';
import Countdown from './countdown.js';
import Winner from './winner.js';
import About from './about.js';
import Settings from './settings.js';

class Raffle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entries: ['Apples', 'Oranges', 'Bananas', 'Pears', 'Strawberries', 'Peaches'],
      shuffleOngoing: false,
      countdownOngoing: false,
      countdownNumber: 3,
      countdownNumberStart: 3
    };
    this.shuffle = this.shuffle.bind(this);
    this.startShuffle = this.startShuffle.bind(this);
    this.drawWinner = this.drawWinner.bind(this);
    this.applyEntries = this.applyEntries.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.entry = document.getElementById('entry');
    this.startShuffle();
  }

  componentWillUnmount() {
    this.drawWinner();
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  startShuffle() {
    this.interval = window.setInterval(() => {
      this.entriesList = this.shuffle(this.state.entries);
      this.entry.textContent = this.entriesList[0];
    }, 30);
    this.setState({ shuffleOngoing: true })
  }

  startCountdown() {
    this.setState({ 
      countdownOngoing: true,
      countdownNumber: this.state.countdownNumberStart
    });
    this.intervalCountdown = window.setInterval(() => {
      this.setState({ countdownNumber: this.state.countdownNumber - 1 })
    }, 1000);
  }

  drawWinner() {
    clearInterval(this.interval);
    clearInterval(this.intervalCountdown);
    this.setState({
      shuffleOngoing: false,
      countdownOngoing: false,
      countdownNumber: this.state.countdownNumberStart
    })
  }

  applyEntries(entries) {
    this.setState({ entries: entries });
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ countdownNumberStart: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid bg-success height-100">
        <Navbar 
          entries={this.state.entries} 
        />
        <Countdown
          countdownOngoing={this.state.countdownOngoing}
          countdownNumber={this.state.countdownNumber}
        />
        <Entries entries={this.state.entries} applyEntries={this.applyEntries} />
        <Settings 
          handleChange={this.handleChange} 
          countdownNumberStart={this.state.countdownNumberStart} 
        />
        <About />
        <div className="row align-items-center text-center m-0 height-100">
          <div className="col">
            <p id='entry' className='h1 text-white text-wrap text-break'></p>
            <Winner shuffleOngoing={this.state.shuffleOngoing} />
          </div>
        </div>
        <NavbarBottom
          drawWinner={this.drawWinner}
          startShuffle={this.startShuffle}
          shuffleOngoing={this.state.shuffleOngoing}
          startCountdown={this.startCountdown} 
          countdownNumberStart={this.state.countdownNumberStart} 
        />
      </div>
    );
  }
}

export default Raffle;
