import React, { Component } from 'react';

class NavbarBottom extends Component {
  render() {
    let navbarBottomButton;
    if (this.props.shuffleOngoing === true) {
      navbarBottomButton = <button
        type="button"
        className="btn btn-warning btn-lg text-uppercase width-100 font-oswald"
        onClick={ (e) => {
          this.props.startCountdown();
          window.setTimeout(this.props.drawWinner, this.props.countdownNumberStart * 1000);
        } }
      >
        Draw Winner
      </button>
    } else {
      navbarBottomButton = <button
        type="button"
        className="btn btn-secondary text-white btn-lg text-uppercase width-100 font-oswald"
        onClick={this.props.startShuffle}
      >
        Start Over?
      </button>
    }

    return (
      <div className="fixed-bottom">
        <nav className="navbar bg-transparent justify-content-center">
          {navbarBottomButton}
        </nav>
        <nav className="navbar bg-transparent justify-content-center">
          <div className="width-100 text-right">
            <a
              className="text-white-50 small"
              href="#about"
              data-toggle="modal">
              About
            </a>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavbarBottom;
