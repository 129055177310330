import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Navbar extends Component {
  render() {
    return (
      <nav className="navbar fixed-top bg-transparent max-width-576">
        <NavLink
          className="navbar-brand mr-auto text-white font-oswald text-uppercase"
          to="/" >
          <h1 className="h3 mb-0 font-weight-bold">Visual Raffle</h1>
        </NavLink>
        <button
          type="button"
          className="btn btn-light font-oswald text-uppercase"
          href="#entries"
          data-toggle="modal">
          Entries <span className="badge badge-success">{this.props.entries.length}</span>
        </button>
        <button
          type="button"
          className="nav-link btn btn-link text-white pr-0"
          href="#settings"
          data-toggle="modal">
          <i className="fas fa-sliders-h fa-lg"></i>
        </button>
      </nav>
    );
  }
}

export default Navbar;
