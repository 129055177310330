import React, { Component } from 'react';

class Countdown extends Component {
  render() {
    let countdownDisplay = this.props.countdownOngoing === false ? "d-none" : "";
    return (
      <div id="countdown" className={countdownDisplay}>
        <div className="row align-items-end text-center height-100 m-0">
          <div className="col">
            <p id="countdownNumber" className='display-1 text-white'>{this.props.countdownNumber}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Countdown;
