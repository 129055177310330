import React, { Component } from 'react';
const $ = window.$;

class Settings extends Component {
  render() {
    return (
      <div className="modal fade" id="settings" tabIndex="-1" role="dialog" aria-labelledby="settingsModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title font-oswald text-uppercase" id="settingsModal">Raffle Settings</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>
                    <div className="form-group">
                        <label 
                        htmlFor="countdownTime" >
                        Countdown: <span className="text-success">{this.props.countdownNumberStart} sec</span>
                        </label>
                        <input 
                        type="range" 
                        className="custom-range slider" 
                        min="0" 
                        max="10" 
                        value={this.props.countdownNumberStart} 
                        onChange={this.props.handleChange} 
                        id="countdownTime" 
                        aria-describedby="countdownTime" />
                    </div>

                    <button
                        type="submit"
                        className="btn btn-success font-oswald text-uppercase"
                        onClick={ (event) => {
                            event.preventDefault();
                            $('#settings').modal('hide');
                        }
                    } >
                    Apply Settings
                    </button>
                </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
