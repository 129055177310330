import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Raffle from './raffle.js';

class Router extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Raffle} />
      </Switch>
    );
  }
}

export default Router;
